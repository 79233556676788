import Navigation from "../../../components/ui/navigation";

const Employees = () => {

	return (

		<>

			<div className="menu">

				<Navigation />

			</div>

			<>Employees</>

		</>

	);

}

export default Employees;
