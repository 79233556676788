import { useState, useEffect } from 'react';
import cssIf from '../../../../scripts/helpers/class.add.if';
import "./toggleslider.scss";

const ToggleSlider = ( props ) => {

  const {

    selected = 0, 
    select, 
    list = [], 
    title = "",
    className = "",
    style = {}

  } = props;

  const randomID = Math.random( 1, 99 ).toFixed( 0 );

  const elementWidth = 100 / list.length;
  const [ anchorOffset, setAnchorOffset ] = useState( 0 );

  useEffect(() => { setAnchorOffset( ( 100 / list.length ) * ( selected - 1 )); }, [ selected ]);
  
  return (

    <div className = {`toggleslider ${ className }`} style = { style }>

      { title?.length > 0 && <div className = "toggleslider__title">{ title }</div> }

      <div className = {`toggleslider__container relative`}>

        <div className = {`toggleslider__list absolute flex`}>

          { list.length > 0 && list.map(( el, key ) => (

            <div
              onClick = { () => { select( key + 1 ) } }
              style = {{ width: `${ elementWidth }%` }}
              className = {`toggleslider__list__element toggleslider__list__element_${ randomID }_${ key } ${ cssIf( selected - 1 === key, `toggleslider__list__element--selected` ) } flex justify-center items-center`} key = { key }
            >
              { el }
            </div>

          ))}

        </div>

        <div

          className = {`toggleslider__anchor absolute`}
          style = {{

            left: `${ anchorOffset }%`,
            width: `${ elementWidth }%`

          }}

        />
        
      </div>
     
    </div>

  );

}

export default ToggleSlider;