import Carcas from "../../../components/containers/carcas";
import DataList from "../../../components/ui/data.list";
import AvatarField from "../../../components/ui/data.list/data.list.fields/avatar.field";
import PriceField from "../../../components/ui/data.list/data.list.fields/price.field";
import SlotField from "../../../components/ui/data.list/data.list.fields/slot.field";
import MultislotField from "../../../components/ui/data.list/data.list.fields/multislot.field";
import DataListControls from "../../../components/ui/data.list.controls";
import useGlobal from "../../../../store";
import { useNavigate } from "react-router-dom";
import { useGet } from "../../../../scripts/backend/hooks";
import DateField from "../../../components/ui/data.list/data.list.fields/date.field";

const Servers = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();

  const [ data, isLoaded ] = useGet('configurator/server');

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/servers/${ data.id }`)

  }

  return (

    <Carcas>

      <DataListControls />

      { !isLoaded ? <p>Загружаю...</p> :

        <DataList

          source = { data }
          onRowClick = { openEditForm }

          widths = "5% 20% 15% 10% 15% 20% 18% 18%"

          names = {[

            "IMG",
            "Название",
            "Стоимость",
            "Корпус",
            "Слоты",
            "Мультислоты",
            "Добавлен",
            "Изменён",

          ]}

          values = {[

            "image",
            "name",
            "price",
            "serverboxHeightName",
            "slots",
            "multislots",
            "created_at",
            "updated_at",

          ]}

          containers = {{ 
            
            0: AvatarField,
            2: PriceField,
            4: SlotField,
            5: MultislotField,
            6: DateField,
            7: DateField,

          }}

        />

      }

    </Carcas>
    
  );

}

export default Servers;
