import Navigation from "../../ui/navigation";
import "./carcas.scss";

const Carcas = ({ children }) => {

  return (

    <main className = "carcas">

      <Navigation />

      <section className = "content">

        { children }

      </section>

    </main>

  );

}

export default Carcas;