import Button from "../../inputs/button";
import Box from "../box";
import "./slots.container.scss";

const SlotsContainer = ( props ) => {

  const {

    children, 
    className = "", 
    add = false,
    addText = "Добавить слот"

  } = props;

  return (

    <Box className = {`slots_cnt flex ${ className }`}>

      { children }

      { add && 
        
        <Button 
        
          className = "slots_cnt__add"
          action = { add }
        
        >
          
          { addText }
        
        </Button>
        
      }

    </Box>

  );

}

export default SlotsContainer;