// import * as popup from './popup';
import * as sign from './sign';

async function changeStates( store, states ) {

  await store.setState( states );
  return true;

}

async function changeObjectState( store, object, state, value ) {

  store.setState({

    [ object ]: {

      ...store.state[ object ],
      [ state ]: value

    }

  });

}

async function updateRender( store ) {

  store.setState({ dummy: Date.now().toString() })

} 

export {

  changeStates,
  changeObjectState,
  updateRender,
  sign,
  
}