
import * as backendCalls from "./calls";
import * as backendHooks from "./hooks";

const backend = {

  ...backendCalls,
  ...backendHooks

}

export default backend;