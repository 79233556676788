import { useLocation, useNavigate } from "react-router-dom";

const useGoBack = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => navigate(`/${ location.pathname.split('/').slice( 0, -1 ).slice( 1 ).join('/') }`)
  
  return goBack;
  // return () => console.log(`/${ location.pathname.split('/').slice( 0, -1 ).slice( 1 ).join('/') }`);

}

export default useGoBack;