import API from "../../../../constants/api";
import backend from "../../../../scripts/backend";
import useGoBack from "../../../../scripts/hooks/use.go.back";
import Carcas from "../../../components/containers/carcas";
import ServerForm from "../../../components/forms/configurator/server.form";

const ServerAdd = () => {

  const goBack = useGoBack();

  function handleAdd( form ) {

    backend.add( API.configurator.server.add, form, goBack );

  }

  return (

    <Carcas>

      <ServerForm 
      
        handleConfirm = { handleAdd }
        handleCancel  = { goBack } 
        
      />

    </Carcas>

  );

}

export default ServerAdd;