import cssIf from "../../../../../../scripts/helpers/class.add.if";

const SlotField = ({ children }) => {

  if ( !children || !children?.length ) { return null; }

  return (
    
    <div>
    
      { children?.map(( slot => (

        <div>

          { slot?.increase === false ? '— ' : slot?.increase === true ? '+ ' : '' }
          { slot?.name } (x{ slot?.amount })
          { cssIf( slot?.onBackPanel || slot?.on_back_panel, " (зад. панель)" ) }
        
        </div>

      )))}

    </div>

  );

}

export default SlotField;