function allObjectsIsHaveFilledKey( array = [], key = "" ) {
  
  let validated = true;

  for ( const el of array ) {

    if ( !validated ) continue;
    if ( !el?.[ key ] ) validated = false;

  }

  return validated;

}

export default allObjectsIsHaveFilledKey;