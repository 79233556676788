import { useEffect, useState } from "react";
import { get as getFromBackend } from "./calls";

const useGet = ( route ) => {

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ data, setData ] = useState([]);

  useEffect(() => {

    ( async () => {

      setIsLoaded( false );
      const response = await getFromBackend( route );
      // TODO catch errors
      setIsLoaded( true );
      setData( response );

    })();

  }, [ route ]);

  return [ data, isLoaded ]

}

export { 
  
  useGet 

}