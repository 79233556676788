import { useEffect } from "react";
import API from "../../../../constants/api";
import backend from "../../../../scripts/backend";
import useGoBack from "../../../../scripts/hooks/use.go.back";
import Carcas from "../../../components/containers/carcas";
import ServerForm from "../../../components/forms/configurator/server.form";
import useGlobal from "../../../../store";
import { useParams } from "react-router-dom";

const ServerEdit = () => {

  const params = useParams();
  const { id } = params;
  const goBack = useGoBack();

  const [ globalState, globalActions ] = useGlobal();
  const { initialData } = globalState;
  const { changeStates } = globalActions;

  useEffect(() => {

    return () => { changeStates({ initialData: {} }) }

  }, []);

  const handleEdit = ( form ) => {
    
    backend.edit( API.configurator.server.edit( id ), form, goBack );
  
  }

  const handleRemove = () => {
    
    if ( !window.confirm('Точно хотите удалить?') ) return;
    backend.remove( API.configurator.server.remove( id ), goBack );
  
  }

  return (

    <Carcas>

      <ServerForm 
      
        initialData   = { initialData }
        handleConfirm = { handleEdit }
        handleRemove  = { handleRemove }
        handleCancel  = { goBack }
        
      />

    </Carcas>

  );

}

export default ServerEdit;