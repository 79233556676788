import API from "../../../../constants/api";
import backend from "../../../../scripts/backend";
import useGoBack from "../../../../scripts/hooks/use.go.back";
import Carcas from "../../../components/containers/carcas";
import ComponentForm from "../../../components/forms/configurator/component.form";

const ComponentAdd = () => {

  const goBack = useGoBack();

  function handleAdd( form ) {

    backend.add( API.configurator.component.add, form, goBack );
    console.log( form )

  }

  return (

    <Carcas>

      <ComponentForm 
      
        handleConfirm = { handleAdd }
        handleCancel  = { goBack } 
        
      />

    </Carcas>

  );

}

export default ComponentAdd;