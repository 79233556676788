import axios from "axios";
import headersWithToken from "../helpers/headers.token"
import { HOST } from "../../constants/secret";
import logOut from "../helpers/logout";

async function get( table = "", params = {} ) {

  try {

    const headers = headersWithToken();
    const response = await axios.get(`${ HOST }/${ table }`, { params, headers });
  
    if ( response?.status !== 200 ) { return false; }

    return response?.data || [];

  } catch ( err ) {

    err?.status === 401 && logOut();

  }

}

async function post( url, form = {}, onSuccess = () => {}, onError = () => {} ) {

  try {

    const headers = headersWithToken();
    const response = await axios.post( url, form, { headers });

    if ( response?.status !== 201 ) { return false; }
    return onSuccess();

  } catch ( err ) {

    console.error( err );
    err?.status === 401 && logOut();

  }

}

async function add( url, form = {}, onSuccess = () => {}, onError = () => {} ) {

  return post( url, form, onSuccess, onError );

}

async function edit( url, form = {}, onSuccess = () => {}, onError = () => {} ) {

  try {

    const headers = headersWithToken();
    const response = await axios.post( url, form, { headers });

    if ( response?.status !== 201 ) { return false; }
    return onSuccess();

  } catch ( err ) {

    console.error( err );
    err?.status === 401 && logOut();

  }

}

async function remove( url, onSuccess = () => {}, onError = () => {} ) {
//// async function remove( url, onSuccess = () => {}, onError = () => {} ) {
  try {

    const headers = headersWithToken();
    const response = await axios.post( url, {}, { headers });

    if ( response?.status !== 201 ) { return false; }
    return onSuccess();

  } catch ( err ) {

    console.error( err );
    err?.status === 401 && logOut();

  }

}

export {

  get,
  post,
  add,
  edit,
  remove,

}