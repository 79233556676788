const RenderJSON = ({ data = {}, ...props }) => {

  return (

    <h5 style = {{ whiteSpace: 'pre-wrap'}} { ...props }>
      
      { data?.length && JSON.stringify( data, 0, 2 ) }
      
    </h5>

  );

}

export default RenderJSON;