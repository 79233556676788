import Navigation from "../../../components/ui/navigation";



const Actives = () => {

  return (

    <div>

      <div className="menu">

        <Navigation />

      </div>

      <>Actives</>

    </div>

  );
  
}
  
export default Actives;
  