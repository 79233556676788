import { useEffect } from "react";
import Box from "../../containers/box";
import Button from "../../inputs/button";
import Checkbox from "../../inputs/checkbox";
import SelectFromBackend from "../../inputs/select.from.backend";
import Textfield from "../../inputs/textfield";
import useIdFromState from "../../../../scripts/hooks/use.id.from.state";
import "./server.slot.scss";

const ServerSlot = ( props ) => {

  const {

    slotId,
    amount,
    onBackPanel,
    set = () => {},
    remove

  } = props;

  const [ slot, setSlot ] = useIdFromState( slotId, id => set("slot_id", id ) );

  useEffect(() => { set("isMultiSlot", slot?.isMultiSlot ) }, [ slot?.isMultiSlot ])

  return (

    <Box className = "srv_slot">

      <div className = "flex">

        <SelectFromBackend

          title = { !slot?.isMultiSlot ? 'Слот:' : 'Мультислот:' }
          source = {`configurator/slotsAndMultislots`}
          select = { setSlot }
          selected = { slot }
          placeholder = "Выбрать"
          className = "srv_slot__selector"

        />

        <Textfield

          title = "Кол-во:"
          type = "number"
          value = { amount || "" }
          set = { n => set("amount", +n ) }
          className = "srv_slot__amount"

        />

      </div>

      <Checkbox

        title = "На задней панели"
        value = { onBackPanel || "" }
        set = { n => set("on_back_panel", n ) }

      />

      <Button action = { remove }>Удалить</Button>

    </Box>

  );

}

export default ServerSlot;