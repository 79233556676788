import Navigation from "../../../components/ui/navigation";

const Published = () => {

  return (

    <>
    
      <div className="menu">

        <Navigation/>

      </div>

      <>Published</>
		
		</>

  );

}

export default Published;
