import { useLocation } from "react-router-dom";
import TopPanel from "../../containers/top.panel";
import { ButtonRouter } from "../../inputs/button";

const DataListControls = ( props ) => {

  const {

    

  } = props;

  const location = useLocation();

  console.log( {location} )

  return (

    <TopPanel>

      <ButtonRouter url = {`${ location.pathname }/add`}>
        Добавить
      </ButtonRouter>

    </TopPanel>

  );

}

export default DataListControls;