import React, { useState } from 'react';

export const Dummy = ({ random = 0 }) => {

  return (

    <span style = {{ display: 'none' }}>
      { random }
    </span>

  )

}

const useReloadDummy = ( setGlobalDummy = () => {} ) => {

  const [ dummy, setDummy ] = useState( RandomHash() );

  const Anchor = () => (

    <Dummy random ={ dummy } />
  
  )

  const Reload = () => { 

    const r = RandomHash();
    setDummy( r );
    setGlobalDummy( r );

  };

  return [ Anchor, Reload ];

}

export const RandomHash = () => {

  let hash = ( Math.random( 10,99 ).toFixed( 3 ) * 1000 ).toString();
  hash += Date.now().toString().substring(0,7);
  hash += ( Math.random( 10,99 ).toFixed( 3 ) * 1000 ).toString();

  return hash;
}

export default useReloadDummy;