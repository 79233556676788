import { HOST } from "./secret";

const API = {

  sign: {

    in: `${ HOST }/auth/login`,
    check: ``,

  },

  configurator: {

    slot: {

      add: `${ HOST }/admin/configurator/slot/add`,
      edit: id => `${ HOST }/admin/configurator/slot/${ id }/update`,
      remove: id => `${ HOST }/admin/configurator/slot/${ id }/delete`,

    },

    multislot: {

      add: `${ HOST }/admin/configurator/multislot/create`,
      edit: id => `${ HOST }/admin/configurator/multislot/${ id }/update`,
      remove: id => `${ HOST }/admin/configurator/multislot/${ id }/delete`,

    },

    component: {

      add: `${ HOST }/admin/configurator/component`,
      edit: id => `${ HOST }/admin/configurator/component/${ id }/update`,
      remove: id => `${ HOST }/admin/configurator/component/${ id }/delete`,

    },

    serverbox: {

      add: `${ HOST }/admin/configurator/serverHeight/add`,
      edit: id => `${ HOST }/admin/configurator/serverHeight/${ id }/update`,
      remove: id => `${ HOST }/admin/configurator/serverHeight/${ id }/delete`,

    },

    server: {

      add: `${ HOST }/admin/configurator/server/add`,
      edit: id => `${ HOST }/admin/configurator/server/${ id }/update`,
      remove: id => `${ HOST }/admin/configurator/server/${ id }/delete`,

    }

  },

  user: {
    
    partner: {

      request: {

        accept: id => `${ HOST }/admin/partner/${ id }/accept`,
        reject: id => `${ HOST }/admin/partner/${ id }/reject`,
      
      }

    }

  }

}

export default API;