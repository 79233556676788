const BACKEND = {

  LOCAL: "http://localhost:3434/api",
  STAGE: "https://trinity-api.fullstack-freelancer.ru/api",
  PROD:  "",

}

const HOST = BACKEND.STAGE;

export {

  HOST

}
