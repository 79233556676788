import Carcas from "../../../components/containers/carcas";
import DataList from "../../../components/ui/data.list";
import DataListControls from "../../../components/ui/data.list.controls";
import { useGet } from "../../../../scripts/backend/hooks";
import useGlobal from "../../../../store";
import { useNavigate } from "react-router-dom";
import PriceField from "../../../components/ui/data.list/data.list.fields/price.field";
import SlotField from "../../../components/ui/data.list/data.list.fields/slot.field";

const Components = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();

  const [ data, isLoaded ] = useGet('configurator/component');

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/components/${ data.id }`)

  }

  return (

    <Carcas>

      <DataListControls />

      { !isLoaded ? <p>Загружаю...</p> :
        
        <DataList

          source = { data }
          widths = "20% 1fr 20% 1fr"
          names = {[ 
            "Тип", 
            "Название", 
            "Цена", 
            "Влияние на слоты", 
          ]}
          values = {[ 
            "type_id",
            "name",
            "price",
            "slots",
          ]}

          containers = {{ 

            2: PriceField,
            3: SlotField,
  
          }}

          onRowClick = { openEditForm }

        />
      
      }

    </Carcas>
    
  );

}

export default Components;
