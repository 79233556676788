import { useEffect, useState } from "react";
import cookie from 'react-cookies';
import Textfield from "../../components/inputs/textfield";
import { useNavigate } from "react-router-dom";
import useGlobal from "../../../store";
import Button from "../../components/inputs/button";

const AuthPage = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;

  const navigate = useNavigate();
  const redirect = () => {
  
    console.log('redirect called');
    navigate('/partners');
  }

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ error, setError ] = useState("");

  const isFormValidated = ( email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && password.length > 0 );

  const token = cookie.load('token');

  useEffect(() => { 
      
    if ( token ) { 
      
      (async () => {

        const tokenIsActual = await sign.Check( token );
        tokenIsActual && redirect();

      })();
    
    }

  }, []);

  useEffect(() => { 
    
    error !== "" && setError("") 
  
  }, [ email, password ]);

  async function handleSignIn() {
    
    return await sign.In({ 
      
      email, 
      password, 
      onSuccess: redirect,
      onError: setError
    
    });
  
  }

  return (

    <div className = "flex-1 grid justify-center items-center">

      <div>

        <h2>Авторизация</h2>
        <br/>

        <Textfield

          title = "e-mail"
          value = { email }
          set = { setEmail }

        />

        <Textfield

          title = "пароль"
          type = "password"
          value = { password }
          set = { setPassword }

        />

        { error !== "" && <p className = "error">{ error }</p> }

        <Button 
        
          action = { handleSignIn }
          disabled = { !isFormValidated }
          
        >

          Войти

        </Button>

      </div>

    </div>

  );

}

export default AuthPage;