import Carcas from "../../../components/containers/carcas";
import DataList from "../../../components/ui/data.list";
import DataListControls from "../../../components/ui/data.list.controls";
import { useGet } from "../../../../scripts/backend/hooks";
import useGlobal from "../../../../store";
import { useNavigate } from "react-router-dom";

const Multislots = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { changeStates } = globalActions;

  const navigate = useNavigate();

  const [ data, isLoaded ] = useGet('admin/configurator/multislot');

  // const data = []
  // const isLoaded = true;

  function openEditForm( data ) {

    changeStates({ initialData: data });
    navigate(`/multislots/${ data.id }`)

  }

  return (

    <Carcas>

      <DataListControls />

      { !isLoaded ? <p>Загружаю...</p> :
        
        <DataList

          source = { data }
          widths = "1fr"
          names = {[ "Название" ]}
          values = {[ "name" ]}
          onRowClick = { openEditForm }

        />
      
      }

    </Carcas>
    
  );

}

export default Multislots;
