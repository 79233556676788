import cookie from 'react-cookies';

function headersWithToken() {

  const token = cookie.load('token');
  if ( !token ) { return {} };

  return { Authorization: `Bearer ${ token }` };

}

export function saveTokenInCookies( token ) {

  if ( !token ) return;
  cookie.save( 'token', token, { path: '/', secure: true } );

}

export default headersWithToken;