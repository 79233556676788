import Box from "../../containers/box";
import Button from "../../inputs/button";
import Checkbox from "../../inputs/checkbox";
import SelectFromBackend from "../../inputs/select.from.backend";
import Textfield from "../../inputs/textfield";
import useIdFromState from "../../../../scripts/hooks/use.id.from.state";
import "./multislot.slot.scss";

const MultislotSlot = ( props ) => {

  const {

    slotId,
    set = () => {},
    remove

  } = props;

  const [ slot, setSlot ] = useIdFromState( slotId, id => set("slot_id", id ) );

  return (

    <Box className = "multi_slot">

      <SelectFromBackend

        title = 'Слот:'
        source = {`configurator/slot`}
        select = { setSlot }
        selected = { slot }
        placeholder = "Выбрать"
        className = "multi_slot__selector"

      />

      <Button action = { remove }>Удалить</Button>

    </Box>

  );

}

export default MultislotSlot;