//* Управление массивом с объектами

import { useState } from "react";

// Хук предназначен для быстрого добавления объектов 
// определенной структуры (emptyObj) в массив, изменения 
// "на лету" значений ключей любого из этих объектов,
// а также удаления объекта либо всех объектов массива.

const useArrayOfObjects = ( 
  
  array = [], 
  setArray = () => {}, 
  emptyObjStructure = {} 

) => {

  const [ _trigger, setTrigger ] = useState();
  const valuesChanged = () => setTrigger(Date.now().toString())

  function add() {

    let slots = array || [];
    slots.push( emptyObjStructure );
    setArray( slots );
    valuesChanged();

  }

  function edit( index, field, value ) {

    let slots = array;
    slots[ index ][ field ] = value;
    setArray( slots );
    valuesChanged();

  }

  function remove( index ) {

    const slots = array;
    slots.splice( index, 1 );
    setArray( slots );
    valuesChanged();

  }

  function clear() {

    setArray([]);
    valuesChanged();

  }

  return {

    add,
    edit,
    remove,
    clear,
    _trigger

  }

}

export default useArrayOfObjects;