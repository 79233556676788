import cssIf from "../../../../../../scripts/helpers/class.add.if";

const MultislotField = ({ children }) => {

  if ( !children || !children?.length ) { return null; }

  return (
    
    <div>
    
      { children?.map(( mslot => (

        <div>{ mslot?.slotNames } (x{ mslot?.amount }){ cssIf( mslot?.onBackPanel, " (зад. панель)" ) }</div>

      ))) }

    </div>

  );

}

export default MultislotField;