import Carcas from "../../../components/containers/carcas";
import backend from "../../../../scripts/backend";
import API from "../../../../constants/api";
import SlotForm from "../../../components/forms/configurator/slot.form";
import useGoBack from "../../../../scripts/hooks/use.go.back";

const SlotAdd = () => {

  const goBack = useGoBack();

  const handleAdd = ( form ) => {
    
    backend.add( API.configurator.slot.add, form, goBack );
  
  }

  return (

    <Carcas>

      <SlotForm 
      
        handleConfirm = { handleAdd }
        handleCancel  = { goBack } 
        
      />

    </Carcas>

  );

}

export default SlotAdd;