import { Fragment } from "react";
import useObjectState from "../../../../../scripts/hooks/use.object.state";
import Textfield from "../../../../components/inputs/textfield";
import ActionButtons from "../../../inputs/action.buttons";

const ServerboxForm = ( props ) => {

  const { 

    initialData = { name: "" }, 
    handleConfirm, 
    handleCancel, 
    handleRemove = false 

  } = props;

  const [ form, setFormField ] = useObjectState( initialData );

  return (

    <Fragment>

      <h2>{ initialData?.id ? 'Редактировать' : 'Добавить' } корпус</h2>
      
      <br/>

      <Textfield

        title = "Название корпуса:"
        value = { form.name }
        set = { n => setFormField("name", n ) }

      />

      <ActionButtons

        confirm = { () => handleConfirm( form ) }
        remove = { handleRemove }
        cancel = { handleCancel }

      />
      
    </Fragment>

  );

}

export default ServerboxForm;