import React, { useState, useRef, useEffect } from 'react';
import cssIf from '../../../../scripts/helpers/class.add.if';
import "./selectfield.scss";

const Selectfield = ( props ) => {

  const {

    selected = false, 
    select, 
    list = [], 
    isLoading = false, 
    title = "", 
    placeholder = "Нажмите, чтобы выбрать", 
    className = "", 
    tip = "",

  } = props;

  const isEmpty = !list.length > 0;
  const LIST_MAX_HEIGHT = 500;

  const listContainer = useRef();
  const [ isOpened, setIsOpened ] = useState( false );
  const [ search, setSearch ] = useState("");
  const searchRef = useRef();

  function Toggle() {

    if ( isEmpty ) { return; }

    if ( !isOpened ) { listContainer.current.style.overflow = "hidden"; }
    setTimeout( () => { searchRef?.current?.focus() }, 150)
    
    setIsOpened( !isOpened );

    let listContainerHeight = listContainer.current.scrollHeight + 20;

    if ( listContainerHeight > LIST_MAX_HEIGHT ) { 
      
      listContainerHeight = LIST_MAX_HEIGHT;
      listContainer.current.style.overflow = "auto";
    
    }
    
    listContainer.current.style.maxHeight = listContainer.current.style.maxHeight ? null : listContainerHeight + "px";

  }

  useEffect(() => {

    if ( selected && list[0]?.id && !selected?.id ) {

      const finded = list.find( el => el.id === selected );
      finded && select( finded );
    
    }

  }, [ selected, list ])

  return (

    <div className = {`selectfield ${ cssIf( isOpened, "selectfield--opened" ) } ${ className }`}>

      { title !== "" && <div className = "selectfield__title">{ title }</div> }

      <div 
        className = {`selectfield__field ${ cssIf( !isLoading && !selected, "selectfield__field--placeholder" )} flex items-center`} 
        onClick = { Toggle }
        title = { tip?.length > 0 ? tip : undefined }
      >

        { !isOpened ? 
          
          <span>
        
            { isLoading 
              ? "Загрузка..." 
                : isEmpty
                  ? "Не найдено" 
                    : selected 
                      ? selected?.name 
                        ? selected?.name 
                          : selected 
                            : placeholder }

          </span>

        : <input 
            
            type = "text"
            ref = { searchRef }
            value = { search }
            onChange = { e => setSearch( e.target.value ) }
            className = "selectfield__field__search relative"
            placeholder = "Поиск по названию"
            onKeyUp = { e => { 
              
              if ( e.key === "Escape" ) { setSearch( "" ); Toggle(); } 
            
            }}

          />

        }

        <div className="selectfield__field__arrow" style = {{ opacity: isEmpty ? 0.3 : 1 }} />
        
      </div>

      <div className = "selectfield__list" ref = { listContainer }>

        { list.length > 0 && 

          list.filter(( item => {

            const name = item?.text || item?.name || item;
            if (!name?.toLowerCase) { return false; }
            return name?.toLowerCase().includes( search.toLowerCase() )

          })).map(( item, key ) => (

            <div
              key = { key }
              title = { item?.tip || "" }
              className = {`selectfield__list__item ${ cssIf( item?.disabled, `disabled` ) } ${ cssIf( item?.hidden, `hidden` ) }`}
              onClick = { () => {

                !item?.disabled && select( item ); 
                Toggle(); 
                
              }}
            >

              { item?.name ? item.name : item }

            </div>

        ))}

      </div>

    </div>

  );

}

export default Selectfield;