import React, { Fragment } from 'react';
import "./textfield.scss";

const Textfield = ( props ) => {
  
  const { 

    value, 
    set = () => {}, 
    title = "", 
    type = "text", 
    placeholder = "", 
    className = "",
    inputClassName = "", 
    refDOM = false, 
    onBlur = () => {}, 
    onKeyUp = () => {},
    icon = <Fragment />

  } = props;

  return (

    <div className = {`textfield ${ className }`}>

      { title !== "" && <div className="textfield__title">{ title }</div> }

      <div className = "relative">

        <input

          type = { type }
          value = { value }
          placeholder = { placeholder }
          className = { inputClassName }
          onChange = { ( event ) => { set( event.target.value ) }}
          onBlur = { onBlur }
          onKeyUp = { onKeyUp }
          ref = { refDOM ? refDOM : null }

        />

        { icon }

      </div>

    </div>

  );

}

export default Textfield;