import { Link, useLocation} from "react-router-dom";
import cssIf from "../../../../scripts/helpers/class.add.if";
import "./navigation.scss";

const Navigation = () => {

  const location = useLocation();
  
  const MENU = [

    {

      title: "Пользователи",

      items: [

        // { text: "Администраторы", url: "/admins" },
        { text: "Партнёры (заявки)", url: "/partner-requests" },
        { text: "Партнёры", url: "/partners" },
        // { text: "Сотрудники", url: "/employees" },

      ],

    },

    {

      title: "Конфигуратор",

      items: [

        { text: "Слоты", url: "/slots" },
        { text: "Мультислоты", url: "/multislots" },
        { text: "Корпуса", url: "/serverboxes" },
        { text: "Сервера", url: "/servers" },
        { text: "Компоненты", url: "/components" },

      ],

    },

    // {

    //   title: "Сделки",

    //   items: [

    //     { text: "В процессе", url: "/actives" },
    //     { text: "Завершенные", url: "/completed" },

    //   ],

    // },

    // {

    //   title: "Новости",

    //   items: [

    //     { text: "Опубликованные", url: "/published" },
    //     { text: "Черновики", url: "/drafts" },
        
    //   ],

    // },

  ];

  const isActive = ( url ) => location.pathname.includes( url );

  return (

    <nav className = "menu">

      <div className = "logo">

        <Link to = "/">TRINITY</Link>

      </div> 

      { MENU.map(( section, sectionIndex ) => (

        <div 
        
          key = { sectionIndex } 
          className = "menu__section"
          
        > 

          <div className = "menu__title">

            { section.title }
            
          </div>

          { section.items.map(( item, key ) => (

            <NavElement

              key = { key }
              url = { item.url }
              text = { item.text }
              isActive = { isActive( item.url ) }
            
            />

          ))}

        </div>

      ))}

    </nav>

  );

}

const NavElement = ({ text, url, isActive = false }) => (

  <Link

    to = { url }
    className = {`menu__link ${ cssIf( isActive, "menu__link--active" ) }`}

  >

    { text }

  </Link>

);

export default Navigation;