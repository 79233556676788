import "./top.panel.scss";

const TopPanel = ({ children }) => {

  return (

    <div className = "tpnl flex justify-end items-center">

      { children }

    </div>

  );

}

export default TopPanel;