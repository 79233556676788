import { useState } from "react"

//* Хук является расширением useState:

// Когда в него записывается объект, кроме того, что объект
// доступен полностью из первого элемента массива, как в useState,
// также в переданную в хук вторым аргументом функцию
// записывается ключ "id" объекта

// Значение стейта используется как selected в Selectfield,
// а id передаётся в форму, которая должна быть отправлена на бэк

const useIdFromState = ( initialState, setIdFromSelectedState = () => {} ) => {

  const [ state, setState ] = useState( initialState );

  function handleSetState( object ) {

    setState( object );
    setIdFromSelectedState( object.id );

  }

  return [ state, handleSetState ];

}

export default useIdFromState;