import { useState } from "react"

const useObjectState = ( initialStates = {} ) => {

  const [ state, setState ] = useState( initialStates );

  function set( key, value ) {

    return setState({

      ...state,
      [ key ]: value

    });

  }

  return [ state, set ];

}

export default useObjectState;