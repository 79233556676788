import getFromObject from "../../../../scripts/helpers/get.from.object";
import "./data.list.scss";

const DataList = ( props ) => {

  const {

    source,
    names,
    values,
    containers,
    widths = undefined,
    onRowClick = () => {}

  } = props;

  return (

    <div className = "datalist">

      <div 
      
        className = "datalist__row datalist__row--head grid items-start"

        style = {{

          gridTemplateColumns: widths,

        }}
        
      >

        { names.map(( el, key ) => ( 
          
          <span key = { key }>{ el }</span>
      
        ))}
        
      </div>

      <div className = "datalist__content grid">

        { source.map(( row, key ) => (

          <div 
          
            key = { key }
            className = "datalist__row grid items-center" 
            onClick = { () => { onRowClick( row ) }}

            style = {{

              gridTemplateColumns: widths,

            }}
            
          >

            { values.map(( col, index ) => {

              const CustomComponent = containers?.[ index ];
              
              return (

                <div className = "table table__col" key = { index }>

                  { CustomComponent

                    ? <CustomComponent 
                    
                        key = { index }
                        row = { row } 
                        
                      >
                        
                        { getFromObject( col, row ) }

                      </CustomComponent>

                    : getFromObject( col, row )
                  
                  }
                  
                </div>

              )
            
            })}

          </div>

        ))}

      </div>

    </div>

  );

}

export default DataList;