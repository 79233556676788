function getFromObject( field, object ) {

  let result = "";

  const levels = field.split('.');

  for ( let i = 0; i < levels.length; i++ ) {

    if ( i === 0 ) {

      if ( !object?.[ levels[ i ] ] ) { break; }

      result = object[ levels[ i ] ];
      continue;

    }

    result = result[ levels[ i ] ];

  }

  return result;

}

export default getFromObject;