import "./box.scss";

const Box = ({ children, className = "", ...props }) => (

  <div
    
    {...props }
    className = {`box ${ className }`}
    
  >

    { children }

  </div>

);

export default Box;