import Carcas from "../../../components/containers/carcas";

const Admins = () => {

	return (

		<Carcas>

			Админы

		</Carcas>

	);

}

export default Admins;
