import cookie from 'react-cookies';
import axios from 'axios';
import API from '../../constants/api';
import logOut from '../../scripts/helpers/logout';

export async function In( store, { email, password, code = undefined, onSuccess = () => {}, onError = () => {} }) {

  try {

    const { data } = await axios.post( API.sign.in, { email, password, code } );

    console.log(`signIn data.token:`, data?.token);

    // if ( !data?.token ) { throw('Неправильные данные'); }

    cookie.save('token', data.token, { path: '/' });
    console.log(`signIn data.token cookie saved:`, data?.token);
    onSuccess();

  } catch ( err ) {

    console.error( err );
    onError( err?.message || "Не удалось войти" );

  }
  
}

export async function Check( store, token, isSubscribeNeed = false, navigate = () => {}, withoutLogout = false ) {
  
  try {

    const { status, data } = await axios.post( API.sign.check, { token: token } );

    if ( !data.success ) { throw( data.error ); }

    // store.setState({ 

    //   user_name: data.user.name,

    // });

    return true;

  } catch ( err ) {

    if ( !withoutLogout ) {

      console.error(`Попытка разлогина из Check:`, err);
      logOut();
    
    }

    return false;    

  }
  
}