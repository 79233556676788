import Box from "../../containers/box";
import Button from "../../inputs/button";
import SelectFromBackend from "../../inputs/select.from.backend";
import Textfield from "../../inputs/textfield";
import ToggleSlider from "../../inputs/toggleslider";
import useIdFromState from "../../../../scripts/hooks/use.id.from.state";
import "./component.slot.scss";

const ComponentSlot = ( props ) => {

  const {

    slotId,
    amount,
    increase,
    set = () => {},
    remove

  } = props;

  const [ slot, setSlot ] = useIdFromState( slotId, id => set( "slot_id", id ) );

  return (

    <Box className = "cmpn_slot">

      <div className = "flex">

        <SelectFromBackend

          title = 'Слот:'
          source = {`configurator/slot`}
          select = { setSlot }
          selected = { slot }
          placeholder = "Выбрать"
          className = "cmpn_slot__selector"

        />

        <Textfield

          title = "Кол-во:"
          type = "number"
          value = { amount || "" }
          set = { n => set("amount", +n ) }
          className = "cmpn_slot__amount"

        />

      </div>

      <ToggleSlider

        title = "Компонент этот слот"
        list = {["Расходует", "Добавляет"]}
        selected = { increase ? 2 : 1 || "" }
        select = { n => set("increase", n === 2 ) }

      />

      <Button action = { remove }>Удалить</Button>

    </Box>

  );

}

export default ComponentSlot;