import useObjectState from "../../../../../scripts/hooks/use.object.state";
import ActionButtons from "../../../inputs/action.buttons";
import Textfield from "../../../inputs/textfield";
import "./partner.form.scss";

const PartnerForm = ( props ) => {

  const {

    initialData = {}, 
    handleConfirm = false, 
    handleCancel = false, 
    handleRemove = false, 
    confirmText = false, 
    cancelText = false, 
    removeText = false 

  } = props;

  const [ form, setForm ] = useObjectState( initialData );

  const {

    inn,
    company_business_line,
    employees_count,
    main_customers,
    owner,
    products_of_interest,
    promoted_products,
    site_url,
    created_at,

  } = form;

  return (

    <div className = "partner_form">

      <div className = "grid">

        <div>

          <Textfield title = "Название компании" value = { owner?.info?.company_name } className = "events-none" />
          <Textfield title = "ИНН" value = { inn } className = "events-none" />
          <Textfield title = "Сайт" value = { site_url } className = "events-none" />
          <Textfield title = "Направление деятельности" value = { company_business_line } className = "events-none" />
          <Textfield title = "Количество сотрудников" value = { employees_count } className = "events-none" />
          <Textfield title = "Продвигаемые продукты" value = { promoted_products } className = "events-none" />
          <Textfield title = "Основные заказчики" value = { main_customers } className = "events-none" />

        </div>

        <div>

          <Textfield title = "Имя" value = { owner?.info?.first_name } className = "events-none" />
          <Textfield title = "Фамилия" value = { owner?.info?.last_name } className = "events-none" />
          <Textfield title = "E-mail" value = { owner?.email } className = "events-none" />
          <Textfield title = "Телефон" value = { owner?.phone } className = "events-none" />
          <Textfield title = "Должность" value = { owner?.info?.job_title } className = "events-none" />
          <Textfield title = "Интересующие продукты" value = { products_of_interest } className = "events-none" />
          <Textfield title = "Дата регистрации" value = { created_at } className = "events-none" />

        </div>

      </div>

      <ActionButtons

        confirm = { handleConfirm && ( () => handleConfirm( form ) ) }
        confirmText = { confirmText }
        cancelText = { cancelText }
        removeText = { removeText }
        remove = { handleRemove }
        cancel = { handleCancel }

      />

    </div>

  );

}

export default PartnerForm;