import Navigation from "../../../components/ui/navigation";


const Completed = () => {

  return (

    <>

      <div className="menu">

        <Navigation/>

      </div>

      <>Completed</>

    </>
  );

}

export default Completed;