import cookie from 'react-cookies';

export default function logOut() {

  const disableLogout = false;
  if ( disableLogout ) { alert('LogOut'); return; }
  
  cookie.remove(`token`);
  window.location = "/auth";

}