import Navigation from "../../../components/ui/navigation";

const Drafts = () => {

  return (

    <>

      <div className="menu">

        <Navigation/>

  		</div>

      <>Drafts</> 
		
		</>

  );

}

export default Drafts;